// 查看用户权限
const authCheck = (type) => {
  const AUTH_FLAG = {
    USER_CREATE: false, // 创建用户
    USER_MODIFY_SELF: false, // 修改用户自身信息
    COMPANY_CREATE: false, // 创建公司
    COMPANY_MODIFY: false, // 修改公司
    COMPANY_CHECK: false, // 查看公司
    COMPANY_RELATED_USER: false, // 关联人员到公司
    COMPANY_ASSIGN_DEVICE: false, // 分配设备到公司
    COMPANY_USER_MODIFY: false, // 修改公司人员信息
    COMPANY_SET_HEAD: false, // 设置公司负责人
    COMPANY_SET_ADMIN: false, // 设置公司管理员
    OILFIELD_CREATE: false, // 创建油田
    OILFIELD_MODIFY: false, // 修改油田
    OILFIELD_CHECK: false, // 查看油田
    OILFIELD_RELATED_USER: false, // 关联人员到油田
    OILFIELD_ASSIGN_DEVICE: false, // 分配设备到油田
    OILFIELD_USER_MODIFY: false, // 修改油田人员信息
    OILFIELD_SET_HEAD: false, // 设置油田负责人
    OILFIELD_SET_ADMIN: false, // 设置油田管理员  
    OILFIELD_SET_OPERATOR: false, // 设置油田操作员  
    OILFIELD_SET_OBSERVER: false, // 设置油田观察员
    DEVICE_CREATE: false, // 创建设备
    DEVICE_MODIFY: false, // 修改设备
    DEVICE_CHECK: false, // 查看设备
    DEVICE_CONTROL: false, // 控制设备
    DEVICE_RELATED_USER: false, // 关联人员到设备
    DEVICE_SET_HEAD: false, // 设置设备负责人  
    DEVICE_SET_OPERATOR: false, // 设置油田操作员  
    DEVICE_SET_OBSERVER: false // 设置油田观察员
  }
  switch (type) {
    case '原始管理员':
      AUTH_FLAG.USER_CREATE = true // 创建用户
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      break;
    case '系统管理员':
      AUTH_FLAG.USER_CREATE = true // 创建用户
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CREATE = true // 创建公司
      AUTH_FLAG.COMPANY_MODIFY = true // 修改公司
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.COMPANY_RELATED_USER = true // 关联人员到公司
      AUTH_FLAG.COMPANY_ASSIGN_DEVICE = true // 分配设备到公司
      AUTH_FLAG.COMPANY_USER_MODIFY = true // 修改公司人员信息
      AUTH_FLAG.COMPANY_SET_HEAD = true // 设置公司负责人
      AUTH_FLAG.COMPANY_SET_ADMIN = true // 设置公司管理员
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.DEVICE_CREATE = true // 创建设备
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      AUTH_FLAG.DEVICE_MODIFY = true // 修改设备
      break;
    case '公司负责人':
      AUTH_FLAG.USER_CREATE = true // 创建用户
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_MODIFY = true // 修改公司
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.COMPANY_USER_MODIFY = true // 修改公司人员信息
      AUTH_FLAG.COMPANY_SET_ADMIN = true // 设置公司管理员
      AUTH_FLAG.OILFIELD_CREATE = true // 创建油田
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.OILFIELD_MODIFY = true // 修改油田
      AUTH_FLAG.OILFIELD_RELATED_USER = true // 关联人员到油田
      AUTH_FLAG.OILFIELD_ASSIGN_DEVICE = true // 分配设备到油田
      AUTH_FLAG.OILFIELD_RELATED_USER = true // 关联人员到油田
      AUTH_FLAG.OILFIELD_ASSIGN_DEVICE = true // 分配设备到油田
      AUTH_FLAG.OILFIELD_USER_MODIFY = true // 修改油田人员信息
      AUTH_FLAG.OILFIELD_SET_HEAD = true // 设置油田负责人
      AUTH_FLAG.OILFIELD_SET_ADMIN = true // 设置油田管理员  
      AUTH_FLAG.OILFIELD_SET_OPERATOR = true // 设置油田操作员  
      AUTH_FLAG.OILFIELD_SET_OBSERVER = true // 设置油田观察员
      AUTH_FLAG.DEVICE_MODIFY = true // 修改设备
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      break;
    case '公司管理员':
      AUTH_FLAG.USER_CREATE = true // 创建用户
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.COMPANY_USER_MODIFY = true // 修改公司人员信息
      AUTH_FLAG.OILFIELD_CREATE = true // 创建油田
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.OILFIELD_MODIFY = true // 修改油田
      AUTH_FLAG.OILFIELD_RELATED_USER = true // 关联人员到油田
      AUTH_FLAG.OILFIELD_ASSIGN_DEVICE = true // 分配设备到油田
      AUTH_FLAG.OILFIELD_USER_MODIFY = true // 修改油田人员信息
      AUTH_FLAG.OILFIELD_SET_HEAD = true // 设置油田负责人
      AUTH_FLAG.OILFIELD_SET_ADMIN = true // 设置油田管理员  
      AUTH_FLAG.OILFIELD_SET_OPERATOR = true // 设置油田操作员  
      AUTH_FLAG.OILFIELD_SET_OBSERVER = true // 设置油田观察员
      AUTH_FLAG.DEVICE_MODIFY = true // 修改设备
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      break;
    case '油田负责人':
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.OILFIELD_MODIFY = true // 修改油田
      AUTH_FLAG.OILFIELD_USER_MODIFY = true // 修改油田人员信息
      AUTH_FLAG.OILFIELD_SET_ADMIN = true // 设置油田管理员  
      AUTH_FLAG.OILFIELD_SET_OPERATOR = true // 设置油田操作员  
      AUTH_FLAG.OILFIELD_SET_OBSERVER = true // 设置油田观察员
      AUTH_FLAG.DEVICE_MODIFY = true // 修改设备
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      AUTH_FLAG.DEVICE_CONTROL = true // 控制设备
      AUTH_FLAG.DEVICE_RELATED_USER = true // 关联人员到设备
      AUTH_FLAG.DEVICE_SET_HEAD = true // 设置设备负责人  
      AUTH_FLAG.DEVICE_SET_OPERATOR = true // 设置油田操作员  
      AUTH_FLAG.DEVICE_SET_OBSERVER = true // 设置油田观察员
      break;
    case '油田管理员':
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.OILFIELD_USER_MODIFY = true // 修改油田人员信息
      AUTH_FLAG.OILFIELD_SET_OPERATOR = true // 设置油田操作员  
      AUTH_FLAG.OILFIELD_SET_OBSERVER = true // 设置油田观察员
      AUTH_FLAG.DEVICE_MODIFY = true // 修改设备
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      AUTH_FLAG.DEVICE_CONTROL = true // 控制设备
      AUTH_FLAG.DEVICE_RELATED_USER = true // 关联人员到设备
      AUTH_FLAG.DEVICE_SET_HEAD = true // 设置设备负责人  
      AUTH_FLAG.DEVICE_SET_OPERATOR = true // 设置油田操作员  
      AUTH_FLAG.DEVICE_SET_OBSERVER = true // 设置油田观察员
      break;
    case '油田操作员':
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      AUTH_FLAG.DEVICE_CONTROL = true // 控制设备
      break;
    case '油田观察员':
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      break;
    case '设备负责人':
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      AUTH_FLAG.DEVICE_CONTROL = true // 控制设备
      AUTH_FLAG.DEVICE_SET_OPERATOR = true // 设置油田操作员  
      AUTH_FLAG.DEVICE_SET_OBSERVER = true // 设置油田观察员

      break;
    case '设备操作员':
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      AUTH_FLAG.DEVICE_CONTROL = true // 控制设备
      break;
    case '设备观察员':
      AUTH_FLAG.USER_MODIFY_SELF = true // 修改用户自身信息
      AUTH_FLAG.COMPANY_CHECK = true // 查看公司
      AUTH_FLAG.OILFIELD_CHECK = true // 查看油田
      AUTH_FLAG.DEVICE_CHECK = true // 查看设备
      break;
    case '普通员工':
      break;
    default:
      break;
  }
  return AUTH_FLAG
}

export default {
  authCheck
}
