<template>
  <div class="base-left-nav-container">
    <img class="logo" src="~assets/img/common/logo.png" alt="logo图" />
    <nav>
      <div
        class="nav-item"
        :class="{ active: index == activeIndex }"
        v-for="(item, index) in navList"
        :key="index"
        @click="go(item.path)"
      >
        <i :class="item.icon"></i>
        <span>{{ item.name }}</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'base-left-nav',
  props: {
    activeIndex: Number
  },
  data() {
    return {
      navList: [
        {
          icon: 'iconfont icon-yonghu',
          name: '用户管理',
          path: '/userList'
        },
        {
          icon: 'iconfont icon-gongsi',
          name: '公司管理',
          path: '/companyList'
        },
        {
          icon: 'iconfont icon-iconfontyoutian',
          name: '油田管理',
          path: '/oilfieldList'
        },
        {
          icon: 'iconfont icon-shebei',
          name: '设备管理',
          path: '/machineList'
        }
      ]
    }
  },
  methods: {
    go(path) {
      this.$router.push(path)
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="less" scoped>
.base-left-nav-container {
  width: 222px;
  min-height: 100vh;
  background: #07222f;
  .logo {
    margin: 13px 0 29px 60px;
    width: 92px;
    height: 40px;
  }
  nav {
    width: 222px;
    height: 240px;
    .nav-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 222px;
      height: 60px;
      cursor: pointer;
      i {
        margin-right: 17px;
        font-size: 19px;
        color: #fff;
      }
      span {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
.active {
  background: #019ee5;
}
</style>
