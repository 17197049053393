<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header
          ><span slot="r"
            ><slot>公司管理 — 客户公司列表</slot></span
          ></base-page-header
        >
        <div class="main-manager">
          <div class="header-serach">
            <div class="left">
              <button
                type="button"
                class="add-btn"
                v-show="authFlag.COMPANY_CREATE"
                @click="go('companyCreate')"
              >
                ＋ 创建客户公司
              </button>
            </div>
          </div>
          <el-table
            :data="tableData"
            :header-cell-style="headerCellStyle"
            :cell-style="cellStyle"
            @sort-change="sortChange"
          >
            <el-table-column
              prop="companyid"
              label="序号"
              min-width="80"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column
              label="公司全名"
              min-width="128"
              sortable="'custom'"
            >
              <template slot-scope="scope">
                <span style="color: #409eff">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nickname"
              label="公司简称"
              min-width="128"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column
              prop="manager_info"
              label="公司负责人"
              min-width="128"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column prop="date" label="创建时间" min-width="128">
            </el-table-column>
            <el-table-column label="操作" min-width="240">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-show="authFlag.COMPANY_MODIFY"
                  @click="goModify(scope.row.companyid, '/companyModifyBasic')"
                  >修改基本信息 |</el-button
                >
                <el-button
                  type="text"
                  @click="goRelation(scope.row, '/userList')"
                  >用户列表 |</el-button
                >
                <el-button
                  type="text"
                  @click="goRelation(scope.row, '/oilfieldList')"
                  >油田列表 |</el-button
                >
                <el-button
                  type="text"
                  @click="goRelation(scope.row, '/machineList')"
                  >设备列表</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.curPage"
            :page-sizes="[20, 40, 60]"
            :page-size="dataSql.count"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import CompanyJS from 'network/company/company.js'
import CommonJS from 'assets/js/common.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 1,
      authFlag: {},
      page: {
        curPage: 1,
        total: 0
      },
      dataSql: {
        session_id: '',
        count: 20,
        page: 0,
        companyid: '',
        sort: '',
        desc: false // 默认升序false
      },
      tableData: []
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 跳转到创建新用户界面
    go(v) {
      this.$router.push(v)
    },
    // 跳转到设置身份权限页面
    goRelation(row, path) {
        this.$router.push({
          path: path,
          query: { companyid: row.companyid }
        })
    },
    // 跳转到修改用户信息界面
    goModify(companyid, path) {
      this.$router.push({
        path: path,
        query: { companyid: companyid }
      })
    },
    handleSizeChange(val) {
      this.dataSql.count = val
      this.getCompanyList()
    },
    handleCurrentChange(val) {
      this.dataSql.page = val - 1
      this.getCompanyList()
    },
    resetSort() {
      this.dataSql.companyid = ''
      this.dataSql.oilfieldid = ''
      this.getCompanyList()
    },

    sortChange(column) {
      console.log(column)
      // prop：排序那列表格的字段名
      // order：排序的方式 ascending == 升序 descending == 降序 null == 不排序
      this.dataSql.sort = column.prop
      this.dataSql.desc = column.order == 'ascending'
      this.getCompanyList()
    },
    /**
     * 网络请求
     */
    // 按手机号查询
    searchUserListByPhone() {},

    // 获取公司列表
    getCompanyList() {
      this.dataSql.session_id = this.$store.state.loginInfo.session_id
      CompanyJS.companyList(this.dataSql).then((res) => {
        if (res.code == 0) {
          this.tableData = res.company_list
          this.page.total = res.total_count
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },

    /**
     * 其它方法
     */
    // 查看权限
    authCheck() {
      this.authFlag = CommonJS.authCheck(this.$store.state.loginInfo.gtype_name)
    },
    headerCellStyle() {
      return {
        height: '41px',
        'line-height': '41px',
        'text-align': 'center',
        'font-size': '16px',
        'font-family': 'Source Han Sans CN',
        color: '#333333',
        background: '#F4F5F7'
      }
    },
    cellStyle() {
      return {
        'text-align': 'center'
        // color: '#000000',
        // 'font-size': '14px',
        // 'font-family': 'Source Han Sans CN'
      }
    }
  },
  mounted() {
    this.authCheck()
    this.getCompanyList()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    position: relative;
    width: 1642px;
    height: 82.5vh;
  }
  .header-serach {
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: space-between;
    // align-items: center;
    width: 1642px;
    height: auto;
    background: #fff;
    .left {
      padding-left: 24px;
    }
    .add-btn {
      width: 141px;
    }

    .add-btn {
      margin: 28px 0;
      height: 40px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      background: rgba(1, 158, 229, 0.6);
      border: none;
      &:active {
        background: rgba(1, 158, 229, 1);
      }
    }
  }
  ::v-deep {
    .el-table__body-wrapper {
      max-height: 61vh;
      overflow-y: scroll;
    }
    .el-input__inner {
      border-radius: 0px;
    }
    .el-pagination {
      position: absolute;
      bottom: 0px;
      right: 24px;
    }
  }
}
</style>
