import { render, staticRenderFns } from "./base-page-header.vue?vue&type=template&id=047238e5&scoped=true&"
import script from "./base-page-header.vue?vue&type=script&lang=js&"
export * from "./base-page-header.vue?vue&type=script&lang=js&"
import style0 from "./base-page-header.vue?vue&type=style&index=0&id=047238e5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "047238e5",
  null
  
)

export default component.exports