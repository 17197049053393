import { request } from '../request';

// 创建客户公司
const companyCreate = function (params) {
  return request({
    url: 'api/company/create',
    method: 'post',
    data: params
  })
};
// 公司列表
const companyList = function (params) {
  return request({
    url: 'api/company/list',
    method: 'post',
    data: params
  })
};
// 公司基本信息
const companyGet = function (params) {
  return request({
    url: 'api/company/get',
    method: 'post',
    data: params
  })
};
// 修改客户公司
const companyUpdate = function (params) {
  return request({
    url: 'api/company/update',
    method: 'post',
    data: params
  })
};
// 用户个人基本信息
const userGet = function (params) {
  return request({
    url: 'api/user/get',
    method: 'post',
    data: params
  })
};
export default {
  companyCreate, companyList, companyGet, companyUpdate, userGet
}
