<template>
  <div class="base-top-nav-container">
    <ul class="top-nav">
      <li class="nav-item" @click="goModify('/userModifyBasic')">
        <i class="iconfont icon-xiugaixinxi"></i><span>修改基本信息</span>
      </li>
      <li class="nav-item" @click="goModify('/userModifyPassword')">
        <i class="iconfont icon-mima"></i><span>修改密码</span>
      </li>
      <li class="nav-item">
        <i class="iconfont icon-yonghu1"></i><span>{{$store.state.loginInfo.name}}</span>
      </li>
      <li class="nav-item" @click="logOut">
        <i class="iconfont icon-power"></i><span>退出</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'base-top-nav',
  props: {},
  data() {
    return {}
  },
  methods: {
    goModify(path) {
      this.$router.push({
        path: path,
        query: { userid: this.$store.state.loginInfo.userid }
      })
    },
    logOut() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    }
  },
  mounted() {
  },
  created() {}
}
</script>

<style lang="less" scoped>
.base-top-nav-container {
  position: relative;
  width: 1698px;
  height: 66px;
  background: #ffffff;
  .top-nav {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    right: 29px;
    width: auto;
    height: 66px;
    .nav-item:not(:first-child) {
      &::before {
        content: '';
        display: inline-block;
        margin: 0 19px;
        width: 1px;
        height: 15px;
        background: #111b29;
      }
    }
    .nav-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: auto;
      height: 66px;
      cursor: pointer;
      i {
        margin-right: 12px;
        font-size: 18px;
        color: #2b2b2b;
      }
      span {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2b2b2b;
      }
    }
  }
}
</style>
