<template>
  <div class="base-page-header-container">
    <p class="title">
      <span class="l"><slot name="r"></slot></span
      ><span class="m"><slot name="m"></slot></span>
      <span class="r"><slot name="l"></slot></span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'base-page-header',
  props: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  created() {}
}
</script>

<style lang="less" scoped>
.base-page-header-container {
  box-sizing: border-box;
  width: 1642px;
  height: 56px;
  line-height: 56px;
  background: #fff;
  border-bottom: 1px solid#E7E7E7;
  .title {
    width: auto;
    height: 19px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #202020;
    &::before {
      content: '';
      display: inline-block;
      margin: 0 11px 0 24px;
      width: 3px;
      height: 19px;
      background: #019ee5;
    }
    .r {
      color: rgba(1, 158, 229, 1);
    }
  }
}
</style>
